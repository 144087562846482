/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// todo: fix titles in landing pages
function SEO({
  description,
  meta,
  title,
  hreflangs,
  slug,
  locale,
  img = "/images/aktoploika-background.jpg"
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            url
            title
            author
            el {
              title
              description
            }
            it {
              title
              description
            }
            en {
              title
              description
            }
            ru {
              title
              description
            }
          }
        }
      }
    `
  )

  const metaTitle = title || site.siteMetadata[locale].title
  const metaDescription = description || site.siteMetadata[locale].description
  const ogImage = `https://www.aktoploika.gr${img}`
  let lang = locale
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {hreflangs &&
        hreflangs.map(hreflang => {
          let key = site.siteMetadata.url + hreflang.url
          if (hreflang.locale === locale)
            return <link key={key + "canonical"} rel="canonical" href={key} />

          return undefined
        })
      }
      {hreflangs &&
        hreflangs.map(hreflang => {
          let key = site.siteMetadata.url + hreflang.url
          if (hreflang.locale === "el")
            return (
              <link
                rel="alternate"
                href={key}
                hreflang="x-default"
                key={key + "x-default"}
              />
            )

          return undefined
        })
      }
      {hreflangs &&
        hreflangs.map(hreflang => {
          let key = site.siteMetadata.url + hreflang.url
          return (
            <link
              key={key}
              rel="alternate"
              href={key}
              hrefLang={hreflang.locale}
            />
          )
        })
      }
    </Helmet>
  )
}

SEO.defaultProps = {
  locale: `en`,
  meta: [],
  description: ``,
  title: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  locale: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
